import styled from 'styled-components';

const Grid = styled.div`
  display: block;
  position: absolute;
  width: 80px;
  height: 80px;
  top: 35%;
  left: 50%;
  transform: translate(-50%, -35%);
`;

const Ball = styled.div`
  @keyframes grid {
    0%,
    100% {
      opacity: 1;
    }
    50% {
      opacity: 0.5;
    }
  }
  position: absolute;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.2);
  animation: grid 1.2s linear infinite;
  &:nth-child(1) {
    top: 8px;
    left: 8px;
    animation-delay: 0s;
  }
  &:nth-child(2) {
    top: 8px;
    left: 32px;
    animation-delay: -0.4s;
  }
  &:nth-child(3) {
    top: 8px;
    left: 56px;
    animation-delay: -0.8s;
  }
  &:nth-child(4) {
    top: 32px;
    left: 8px;
    animation-delay: -0.4s;
  }
  &:nth-child(5) {
    top: 32px;
    left: 32px;
    animation-delay: -0.8s;
  }
  &:nth-child(6) {
    top: 32px;
    left: 56px;
    animation-delay: -1.2s;
  }
  &:nth-child(7) {
    top: 56px;
    left: 8px;
    animation-delay: -0.8s;
  }
  &:nth-child(8) {
    top: 56px;
    left: 32px;
    animation-delay: -1.2s;
  }
  &:nth-child(9) {
    top: 56px;
    left: 56px;
    animation-delay: -1.6s;
  }
`;

type Props = {
  className?: string;
};

export const NineDotLoader = ({ className }: Props) => (
  <Grid className={className}>
    <Ball />
    <Ball />
    <Ball />
    <Ball />
    <Ball />
    <Ball />
    <Ball />
    <Ball />
    <Ball />
  </Grid>
);

export default NineDotLoader;
