import { useAuth0 } from '@auth0/auth0-react';
import { Method, ErrorType, Data, APIOptions } from 'types/api-types';
import { useState, useCallback, useEffect } from 'react';
import axios from 'axios';
import { saveAs } from 'file-saver';
import { gatewayList } from 'constants/gateways';

export const useApi = <DataType>(
  url: string,
  method: Method = 'get',
  options: APIOptions | null = null
) => {
  const [info, setInfo] = useState<{
    data: Partial<Data<DataType>> | null;
    error: ErrorType | null;
    isLoading: boolean;
    shouldCallApi: boolean;
    requestData?: Partial<DataType>;
    requestDone: boolean;
    dataIsDirty: boolean;
  }>({
    data: null,
    error: null,
    isLoading: false,
    shouldCallApi: false,
    requestData: {},
    requestDone: false,
    dataIsDirty: false
  });
  const { getAccessTokenSilently, loginWithRedirect } = useAuth0();
  // const [startCall, setStartCall] = useState<number | null>(null);

  const updateDataManualy = (data: Data<DataType>) => {
    setInfo(prevInfo => ({
      ...prevInfo,
      data,
      dataIsDirty: true
    }));
  };

  const call = useCallback(
    (requestData: any) => {
      setInfo(i => ({
        ...i,
        requestData,
        shouldCallApi: true
      }));
    },
    [url, method]
  );

  const callApi = async (data?: Partial<DataType> /* , urlParams?: any */) => {
    setInfo(i => ({
      ...i,
      isLoading: true,
      requestDone: false,
      error: null,
      shouldCallApi: false,
      dataIsDirty: false
    }));
    const token = await getAccessTokenSilently();

    const getHeaders = () => {
      let heads = {};
      if (!options?.isExternal) {
        heads = { Authorization: `Bearer ${token}` };
      }
      if (options?.contentHeader) {
        heads = { ...heads, 'Content-Type': options.contentHeader };
      }
      return heads;
    };

    try {
      const requestUrl = options?.gateway
        ? `${gatewayList[options?.gateway]}/${url}`
        : `${CONFIG.API_BASE_URL}/${url}`;
      /* if (urlParams) {
          const queryString = Object.entries(urlParams).reduce((acc, item) => {
            if (acc === '') {
              return `?${item[0]}=${item[1]}`;
            }
            return `${acc}&${item[0]}=${item[1]}`;
          }, '');
          requestUrl = `${requestUrl}${queryString}`;
        } */
      const response = await axios.request<any>({
        method,
        url: options?.isExternal ? url : requestUrl,
        headers: getHeaders(),

        ...{ data }
      });
      if (
        response?.status === 200 &&
        ((response.data && (!response?.data?.errors || response?.data?.errors?.errorCode === 0)) ||
          !response.data)
      ) {
        if (options?.file) {
          const download = new File([response?.data], options.file.fileName, {
            type: options.file.fileType
          });
          saveAs(download);
        }

        setInfo({
          data: response?.data,
          error: null,
          isLoading: false,
          requestDone: true,
          shouldCallApi: false,
          dataIsDirty: false
        });
        if (options?.onSuccess) {
          options.onSuccess(response?.data);
        }
      } else {
        setInfo({
          data: null,
          error: response?.data?.errors,
          requestDone: true,
          isLoading: false,
          shouldCallApi: false,
          dataIsDirty: false
        });
        if (options?.onError) {
          options.onError(response?.data?.errors);
        }
      }
    } catch (e: any) {
      setInfo({
        data: null,
        error: e?.response,
        requestDone: true,
        isLoading: false,
        shouldCallApi: false,
        dataIsDirty: false
      });
      if (options?.onError) {
        options.onError(e?.response);
      }
    }
  };

  useEffect(() => {
    if (info.shouldCallApi) {
      callApi(info.requestData);
    }
  }, [info.shouldCallApi]);

  return {
    // callApi: (variables?: CallApiParams) => callApi(variables?.data, variables?.urlParams),
    isLoading: info.isLoading,
    requestDone: info.requestDone,
    error: info.error,
    data: info.data,
    callApi: (data?: Partial<DataType>) => call(data),
    updateData: (data: Data<DataType>) => updateDataManualy(data)
  };
};
