import { NavLink } from 'react-router-dom';
/* eslint-disable jsx-a11y/label-has-associated-control */
import styled from 'styled-components';
import NavBarDropdown from './navBarDropdown';

const Nav = styled.nav`
  min-height: ${props => `calc(100vh - ${props.theme.headerHeight})`};
  width: 155.03px;
  height: 750.03px;
  background: #f2f2f2 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  opacity: 1;
  text-align: left;
  display: flex;
  flex-direction: column;
  &:first-child {
    height: 3rem;
  }
`;
const StyledLink = styled(NavLink)`
  width: 10px;
  color: ${props => props.theme.colors.black};
  font-family: ${props => props.theme.fonts.semiBold};
  font-size: 12px;
  text-decoration: none;
  cursor: pointer;
  margin: 5px 0 0 5px;
  padding-bottom: 3px;
  :hover {
    color: ${props => props.theme.colors.red};
  }
  &.active {
    border-bottom-color: white;
  }
  @media (max-width: 1200px) {
    font-size: 12px;
    margin: 5px 0 0 5px;
    font-family: ${props => props.theme.fonts.semiBold};
  }
`;

export const NavBar = () => {
  const Routes = [
    {
      id: 'Devices',
      title: 'Device Management',
      routes: [
        { route: '/devices', routeTitle: 'Devices' },
        { route: '/defaultsettings', routeTitle: 'Default Settings' },
        { route: '/usresettings', routeTitle: 'User Settings' }
      ]
    },
    {
      id: 'Upload',
      title: 'Upload',
      routes: [
        { route: '/servicemonitor', routeTitle: 'Service Monitor' },
        { route: '/uploadmonitor', routeTitle: 'Upload Monitor' },
        { route: '/certrefresh', routeTitle: 'Cert Refresh' },
        { route: '/gemstonecolumnmapping', routeTitle: 'Gemstone Column Mapping' },
        { route: '/gemstonevaluemapping', routeTitle: 'Gemstone Value Mapping' },
        { route: '/gemstonemanagevalidvalues', routeTitle: 'Gemstone Values Management' }
      ]
    }
  ];
  return (
    <Nav>
      {Routes.map(route => (
        <NavBarDropdown routes={route.routes} id={route.id} title={route.title} />
      ))}

      <StyledLink to="/deleteddiamonds">Deleted Diamonds</StyledLink>
    </Nav>
  );
};
export default NavBar;
