import { createGlobalStyle } from 'styled-components';
import { scrollbarStyles } from 'components/common/scrollbar';

export const GlobalStyle = createGlobalStyle` 


html {
  font-size: 10px;
  color: ${props => props.theme.colors.black};
  font-family: ${props => props.theme.fonts.regular}; 
}
.MuiModal-root {
  .MuiMenu-paper {
    box-shadow: 0px 0px 5px #00000029;
    ${scrollbarStyles}
    .MuiMenu-list {
      padding: 0;
      ${scrollbarStyles}
    }
  }
}
.MuiTooltip-popper {
  .MuiTooltip-tooltip {
    background-color: ${props => props.theme.colors.lightBlue};
    font-family: ${props => props.theme.fonts.semiBold}; 
    font-size: 1rem;
    margin-top: 6px !important; 
    left: 3px;
  }
.MuiTooltip-arrow {
    color: ${props => props.theme.colors.lightBlue};
    left: 1.5px !important;
  }}
`;
