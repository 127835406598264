import { Auth0Provider } from '@auth0/auth0-react';
import { ReactNode } from 'react';
import storageService from 'services/storage-service';

type Props = {
  children: ReactNode;
};

export const AuthProvider = ({ children }: Props) => {
  const onRedirectCallback = () => {
    const lastPage = storageService.get('ls.redirectPostLoginUrl');
    window.history.pushState({}, '', lastPage || `${window.location.origin}/#/`);
  };

  return (
    <Auth0Provider
      domain={CONFIG.AUTH0_DOMAIN}
      clientId={CONFIG.AUTH0_CLIENT_ID}
      redirectUri={window.location.origin}
      audience={CONFIG.AUDIENCE_AUTH0}
      onRedirectCallback={onRedirectCallback}
      scope="openid app_metadata"
      useRefreshTokens
      cacheLocation="localstorage"
    >
      {children}
    </Auth0Provider>
  );
};

export default AuthProvider;
