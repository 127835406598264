class StorageService {
  constructor() {
    //  Update the storageVersion if storage changes and does
    //  not support the current stored storage. This will force all clients to login again
    const storageVersion = '1.0';
    let currentStorageVersion;

    try {
      currentStorageVersion = this.get('storageVersion');
    } catch (e) {
      currentStorageVersion = null;
    }

    if (!currentStorageVersion || currentStorageVersion !== storageVersion) {
      this.set('storageVersion', storageVersion);
    }
  }

  get(key: string) {
    try {
      const storedValue = localStorage.getItem(key);
      return storedValue && JSON.parse(storedValue).value;
    } catch (e) {
      return null;
    }
  }

  remove(key: string) {
    try {
      localStorage.removeItem(key);
    } catch (e) {
      return null;
    }
  }

  set(key: string, value: string) {
    try {
      localStorage.setItem(key, JSON.stringify({ value }));
    } catch (e) {
      return null;
    }
  }
}

export default new StorageService();
