import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { useState } from 'react';
import ClickableIcon from 'components/common/icon/clickable-icon';

const StyledLink = styled(NavLink)`
  color: ${props => props.theme.colors.black};
  font-family: ${props => props.theme.fonts};
  font-size: 1.2rem;
  text-decoration: none;
  cursor: pointer;
  margin: 5px 0 0 -20px;
  padding-bottom: 3px;
  padding-top: 3px;
  &.active {
    border-bottom-color: white;
  }
  @media (max-width: 1200px) {
    font-size: 1.2rem;
    font-family: ${props => props.theme.fonts};
  }
`;

const Ul = styled.ul`
  clear: both;
  overflow: hidden;
  text-align: left;
  transition: height 0.4s ease;
`;

const Li = styled.li`
  list-style: none;
  /* color: #2d2f31; */
`;

const Nav = styled.nav`
  /* width: 116px; */
  background: #f2f2f2;
  :first-child {
    margin-top: 35px;
  }
  .slide ${Li} {
    margin: 0px auto 15px 0px;
    :last-child {
      margin: 0px;
    }
  }
`;
const ArrowIcon = styled(ClickableIcon).attrs({
  name: 'arrow'
})`
  font-size: 0.5rem;
  color: ${props => props.theme.colors.black};
  margin-right: 20px;
`;
const Div = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
`;

type TitleType = {
  isOpen?: boolean;
};

const Title = styled.span<TitleType>`
  margin: 0px 10px 0px 5px;
  background: #f2f2f2 0% 0% no-repeat padding-box;
  color: ${props => props.theme.colors.black};
  opacity: 1;
  font-size: 12px;
  font-family: ${props => props.theme.fonts.semiBold};
  cursor: pointer;
  display: block;

  :hover {
    color: ${props => props.theme.colors.red};
  }
  &::after {
    font-family: ${props => props.theme.fonts.bold};
    float: right;
    font-weight: bold;
    font-size: 1.5rem;
    right: 10%;
  }
`;

const Input = styled.input`
  position: absolute;
  opacity: 0;
  height: 0px;
  text-decoration: none;
`;
const Saperetor = styled.div`
  height: 10px;
  width: 90%;
  margin-left: 5px;
  margin-bottom: 10px;
  border-bottom: 1px solid #d6d9de;
`;

type NavBarDropdown = {
  routes: Array<{ route: string; routeTitle: string }>;
  id: string;
  title: string;
};
export const NavBarDropdown = ({ routes, id, title }: NavBarDropdown) => {
  const [open, setOpen] = useState(false);
  return (
    <Nav>
      <label htmlFor={id}>
        <Div>
          <Title isOpen={open}>{title}</Title>
          <ArrowIcon onClick={() => setOpen(!open)} />
          <Input onClick={() => setOpen(!open)} type="checkbox" id={id} />
        </Div>
      </label>

      <Ul hidden={!open} className={open ? 'slide' : ''}>
        {routes &&
          routes.map(route => (
            <Li>
              <StyledLink to={route.route}>{route.routeTitle}</StyledLink>
            </Li>
          ))}
      </Ul>
      <Saperetor />
    </Nav>
  );
};
export default NavBarDropdown;
