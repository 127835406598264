import { css } from 'styled-components';

export const scrollbarStyles = css`
  &::-webkit-scrollbar {
    width: 7px;
    height: 7px;
    background-color: #f0f0f0;
  }
  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.2);
    border: 1px solid #f0f0f0;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.4);
    border: 1px solid #f0f0f0;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: rgba(0, 0, 0, 0.1);
  }
  &::-webkit-scrollbar-thumb:active {
    background: rgba(0, 0, 0, 0.1);
  }
`;
