import styled, { css } from 'styled-components';
import NineDotLoader from './nine-dot-loader';

const LoaderContainer = styled.div`
  position: relative;
  min-height: 100%;
  min-width: 100%;
`;

type OverlayProps = {
  show: boolean;
};

const Overlay = styled.div<OverlayProps>`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  min-height: 100%;
  min-width: 100%;
  background-color: rgba(250, 250, 250, 0.9);
  z-index: 1 !important;
  transition: all 0.15s linear;
  overflow: hidden;
  ${props =>
    !props.show &&
    css`
      display: none;
    `}
`;

type LoaderProps = {
  isLoading?: boolean;
  className?: string;
};

export const Loader = ({ isLoading = false, className }: LoaderProps) => {
  return (
    <LoaderContainer className={className}>
      <Overlay show={isLoading} className="loaderOverlay">
        <NineDotLoader className="loaderSpinner" />
      </Overlay>
    </LoaderContainer>
  );
};

export default Loader;
