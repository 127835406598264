import { ReactNode } from 'react';
import styled from 'styled-components';
import Icon from './icon';

const StyledButton = styled.button`
  position: relative;
  width: 1.7em;
  height: 1.7em;
  border-radius: 4px;
  font-size: 1.5em;
  padding: 0;
  outline: none;
  border: none;
  transition: all 0.3s ease-in-out;

  &:active,
  &:focus,
  &:hover {
    background: rgba(0, 0, 0, 0.08);
  }
  &[disabled],
  &[disabled]:hover,
  &[disabled]:focus {
    opacity: 0.5;
    pointer-events: none;
    cursor: default;
  }
`;

const StyledIcon = styled(Icon)`
  position: absolute;
  top: 0.3em;
  left: 0.3em;
`;

type ClickableIconProps = {
  name: string;
  className?: string;
  onClick?: () => void;
  children?: ReactNode;
  submit?: boolean;
  buttonId?: string;
  iconId?: string;
  title?: string;
};

export const ClickableIcon = ({
  name,
  className,
  onClick,
  children,
  submit,
  buttonId,
  iconId,
  title,
  ...props
}: ClickableIconProps) => (
  <StyledButton
    className={`${className} clickableIcon`}
    onClick={onClick}
    type={submit ? 'submit' : 'button'}
    id={buttonId}
    title={title}
    {...props}
  >
    <StyledIcon id={iconId} className="icon" name={name} {...props} />
    {children}
  </StyledButton>
);

export default ClickableIcon;
