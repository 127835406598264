import { rgba } from 'polished';
import { DefaultTheme } from 'styled-components';

export const theme: DefaultTheme = {
  headerHeight: '59px',
  fonts: {
    light: 'Montserrat-Light',
    regular: 'Montserrat-Regular',
    bold: 'Montserrat-Bold',
    medium: 'Montserrat-Medium',
    semiBold: 'Montserrat-SemiBold'
  },
  colors: {
    red: '#c10230',
    lightBlue: '#009ED3',
    navyBlue: '#00386E',
    black: 'black',
    focusShadowLightBlue: rgba(0, 185, 247, 0.5), // lighten 7% and .5 opacity
    focusShadowNavyBlue: rgba(0, 56, 110, 0.5), //  .5 opacity
    hoverBackgroundLightBlue: '#008bb9', // darken 5%
    hoverBackgroundNavyBlue: '#002c56',
    hoverBorderLightBlue: '#0078a0', // darken 10%
    hoverBorderNavyBlue: '#002c56',
    white: 'white',
    inputBorderGrey: '#CCD6E2',
    backgroundGrey: '#f4f6fa',
    gray: '#E5E5E5'
  },
  icons: {
    x: '"\\e90d"',
    arrow: '"\\e90e"',
    pencil: '"\\e920"',
    check: '"\\e91a"',
    magnifyGlass: '"\\e901"',
    house: '"\\e902"',
    location: '"\\e903"',
    export: '"\\ee58"',
    plus: '"\\e95e"',
    time: '"\\e904"',
    save: '"\\e907"',
    trash: '"\\e93c"',
    info: '"\\e958"',
    cert: '"\\e97d"',
    upload: '"\\e905"',
    download: '"\\e90a"',
    pdf: '"\\e909"',
    redo: '"\\e90b"',
    logout: '"\\e908"',
    remove: '"\\e910"',
    search: '"\\e90f"',
    clearCache: '"\\e90c"',
    copy: '"\\e900"'
  }
};
