import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import { useEffect, Suspense } from 'react';
import { ToastContainer } from 'react-toastify';
import SiteHeader from 'components/site-header/site-header';
import { NavBar } from 'components/navBar/navBar';
import { useAuth0 } from '@auth0/auth0-react';
import Loader from 'components/common/loaders/loader';
import 'react-toastify/dist/ReactToastify.min.css';
// import { getTitle } from 'utils/page-texts';
import { GlobalStyle } from './global-styles';
import Routes from './routes';

const SiteWrapper = styled.div``;

const SiteBody = styled.section`
  width: 100%;
  background: ${props => props.theme.colors.backgroundGrey};
  box-sizing: border-box;
  min-height: ${props => `calc(100vh - ${props.theme.headerHeight})`};
`;

const LoginLoader = styled(Loader)`
  .loaderOverlay {
    width: 100vw;
    height: 100vh;
    position: fixed;
    z-index: 99999999 !important;
    background-color: ${props => props.theme.colors.white};
  }
  .loaderSpinner {
    position: relative;
    top: 40%;
  }
`;

const Toastify = styled(ToastContainer)`
  .Toastify__toast-body {
    color: ${props => props.theme.colors.white};
    font-size: 1.5rem;
    font-family: ${props => props.theme.fonts.semiBold};
  }
  .Toastify__close-button {
    color: ${props => props.theme.colors.white};
    opacity: 1;
  }
  .Toastify__toast-icon {
    margin-right: 15px;
  }
  .Toastify__toast--error {
    background: #d50f00;
    svg {
      fill: ${props => props.theme.colors.white};
    }
  }
  .Toastify__toast--success {
    background: #01ba88;
    svg {
      fill: ${props => props.theme.colors.white};
    }
  }
`;
const Flex = styled.div`
  display: flex;
`;

export const App = () => {
  const { isLoading, isAuthenticated, loginWithRedirect, user } = useAuth0();
  const location = useLocation();
  useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      loginWithRedirect();
    }
  }, [isLoading, isAuthenticated]);

  useEffect(() => {
    if (isAuthenticated) {
      // const extraText = getTitle(location?.pathname || '');
      document.title = 'Manage RapNet';
    }
  }, [location?.pathname, isAuthenticated]);

  useEffect(() => {
    if (user) {
      const permissions = user['http://rapaport.com/permissions'].backoffice;

      if (!permissions?.includes('basic') && !permissions?.includes('admin')) {
        loginWithRedirect();
      }
    }
  }, [user]);

  return (
    <>
      <GlobalStyle />
      <SiteWrapper>
        <LoginLoader isLoading={isLoading} />
        {!isLoading && (
          <>
            <SiteHeader />
            <Flex>
              <NavBar />
              <SiteBody>
                <Suspense fallback={<LoginLoader isLoading />}>
                  <Routes />
                </Suspense>
              </SiteBody>
            </Flex>
          </>
        )}
      </SiteWrapper>
      <Toastify
        hideProgressBar
        newestOnTop
        autoClose={5000}
        draggable={false}
        className="toastifyContainer"
        limit={5}
      />
    </>
  );
};
