import { Switch, Redirect, Route } from 'react-router-dom';
import { lazy } from 'react';
import PrivateRoute from 'components/common/private-route';

const Devices = lazy(() => import('./pages/devices'));
const UserSettings = lazy(() => import('./pages/user-settings'));
const DefaultSettings = lazy(() => import('./pages/default-settings'));
const ServiceMonitor = lazy(() => import('./pages/service-monitor'));
const CertRefresh = lazy(() => import('./pages/cert-refresh'));
const UploadMonitor = lazy(() => import('./pages/upload-monitor'));
const DeletedDiamonds = lazy(() => import('./pages/deleted-diamonds'));

const GemstoneColumnMapping = lazy(() => import('./pages/gemstone-column-mapping'));
const GemstoneValueMapping = lazy(() => import('./pages/gemstone-value-mapping'));
const GemstoneManageValidValues = lazy(() => import('./pages/gemstone-manage-valid-values'));

// const OrderItem = lazy(() => import('./pages/order-item'));
// const Shipout = lazy(() => import('./pages/shipout'));
// const ShipinItem = lazy(() => import('./pages/shipin-item'));
// const ShipoutItem = lazy(() => import('./pages/shipout-item'));
// const QCItem = lazy(() => import('./pages/qc-item'));
// const POItem = lazy(() => import('./pages/po-item'));
// const InvoiceItem = lazy(() => import('./pages/invoice-item'));
// const DbUpdates = lazy(() => import('./pages/db-updates'));

export const Routes = () => {
  return (
    <Switch>
      <PrivateRoute path="/devices" component={Devices} />
      <PrivateRoute path="/defaultsettings" component={DefaultSettings} />
      <PrivateRoute path="/usresettings" component={UserSettings} />
      <PrivateRoute path="/servicemonitor" component={ServiceMonitor} />
      <PrivateRoute path="/uploadmonitor" component={UploadMonitor} />
      <PrivateRoute path="/certrefresh" component={CertRefresh} />
      <PrivateRoute path="/gemstonecolumnmapping" component={GemstoneColumnMapping} />
      <PrivateRoute path="/gemstonevaluemapping" component={GemstoneValueMapping} />
      <PrivateRoute path="/gemstonemanagevalidvalues" component={GemstoneManageValidValues} />

      <PrivateRoute path="/deleteddiamonds" component={DeletedDiamonds} />
      {/* <PrivateRoute path="/orderitem/:id" component={OrderItem} />
      <PrivateRoute path="/shipinitem/:id" component={ShipinItem} />
      <PrivateRoute path="/shipout" component={Shipout} />
      <PrivateRoute path="/shipoutitem/:id" component={ShipoutItem} /> */}
      {/* <PrivateRoute path="/qcitem/:id" component={QCItem} /> */}
      {/* <PrivateRoute path="/poitem/:id" component={POItem} /> */}
      {/* <PrivateRoute path="/invoiceitem/:id" component={InvoiceItem} /> */}

      {/* <PrivateRoute path="/dbupdates" component={DbUpdates} /> */}

      <Route path="/" exact render={() => <Redirect to="/devices" />} />
      {/* <Route path="/orders" exact render={() => <Redirect to="/orders/allorders" />} /> */}
      {/* <Route path="/orderitems" exact render={() => <Redirect to="/orderitems/allorders" />} /> */}
    </Switch>
  );
};

export default Routes;
