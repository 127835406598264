import styled from 'styled-components';
import { NavLink, useLocation } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { getTitle } from 'utils/page-texts';
import { apis } from 'constants/apis';
import { useApi } from 'hooks/useApi';
import defaultImage from 'assets/images/accountImagePlaceholder.png';
import Image from 'components/common/image/image';
import { useAuth0 } from '@auth0/auth0-react';
import { ClickableIcon } from 'components/common/icon/clickable-icon';

const Header = styled.header`
  height: 50px;
  background: ${props => props.theme.colors.red};
  color: ${props => props.theme.colors.white};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1.188rem;
`;

const Title = styled.div`
  top: 17px;
  left: 19px;
  width: 134px;
  height: 19px;
  /* UI Properties */
  color: var(--unnamed-color-ffffff);
  text-align: left;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  font-size: 16px;
  font-family: ${props => props.theme.fonts.bold};
`;

const Right = styled.div`
  display: flex;
`;

const Links = styled.nav`
  display: flex;
  align-items: center;
`;

const StyledLink = styled(NavLink)`
  color: ${props => props.theme.colors.white};
  font-family: ${props => props.theme.fonts.semiBold};
  font-size: 1.4rem;
  text-decoration: none;
  cursor: pointer;
  margin: 0 25px 0 0;
  padding-bottom: 3px;
  padding-top: 3px;
  border-bottom: 2px solid transparent;
  &.active {
    border-bottom-color: white;
  }
  @media (max-width: 1200px) {
    font-size: 1.2rem;
    margin-right: 18px;
  }
`;

const TitleExtraHeader = styled.span`
  font-family: ${props => props.theme.fonts.semiBold};
  font-size: 2rem;
  &:before {
    content: '|';
    margin-right: 4px;
    position: relative;
    top: -3px;
    font-size: 1.6rem;
    font-family: ${props => props.theme.fonts.light};
    @media (max-width: 1200px) {
      font-size: 1.4rem;
    }
  }
  @media (max-width: 1200px) {
    font-size: 1.8rem;
  }
`;

const UserSection = styled.div`
  display: flex;
  align-items: center;
`;

const AccountImage = styled(Image)`
  width: 29px;
  height: 29px;
  border-radius: 50%;
  border: 1px solid ${props => props.theme.colors.white};
`;

const UserName = styled.div`
  font-size: 1.4rem;
  font-family: ${props => props.theme.fonts.medium};
`;

const LogoutContainer = styled.div`
  display: flex;
  cursor: pointer;
  vertical-align: middle;
  text-decoration: underline;
  align-items: center;
`;

const UserTextSection = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 10px;
`;

const Logout = styled(ClickableIcon).attrs({
  name: 'logout'
})`
  color: ${props => props.theme.colors.white};
  background: transparent;
  text-align: center;
  text-decoration: underline;
  cursor: pointer;
  :hover {
    background: transparent;
  }
`;

type LogoData = {
  logo: any;
};
type ContactData = {
  contact: {
    email: string;
    companyName?: string;
    location?: string;
    accountName?: string;
    accountCountry?: string;
    ofcAccountId: number | string;
    ofcContactId?: number | string;
    firstName?: string;
    lastName?: string;
    accountId?: string;
    accountState?: string | null;
    id?: string;
    masterAccountNumber?: string;
    masterContactNumber?: string;
  };
};

export const SiteHeader = () => {
  const location = useLocation();
  const extraText = getTitle(location?.pathname);
  const { isAuthenticated, logout } = useAuth0();
  const [userName, setUserName] = useState('');
  const [userAccId, setUserAccId] = useState(94944);

  const logoApiResult = useApi<LogoData>(apis.companyLogo.url(userAccId));

  const { callApi } = useApi<ContactData>(
    apis.currentContactInfo.url(),
    apis.currentContactInfo.method,
    {
      onSuccess: response => {
        if (response?.data?.contact?.ofcAccountId) {
          setUserName(`${response.data.contact.firstName} ${response.data.contact.lastName}`);
          setUserAccId(response.data.contact.ofcAccountId);
          logoApiResult.callApi();
        }
      }
    }
  );

  useEffect(() => {
    if (isAuthenticated) {
      callApi();
    }
  }, [isAuthenticated]);

  return (
    <Header>
      <Title>Manage.RapNet</Title>
      <Right>
        <UserSection>
          <UserTextSection>
            {/* <UserName>{userName}</UserName> */}

            <LogoutContainer
              onClick={() => {
                if (isAuthenticated) {
                  logout();
                }
              }}
            >
              LogOut
              <Logout />
            </LogoutContainer>
          </UserTextSection>
        </UserSection>
      </Right>
    </Header>
  );
};

export default SiteHeader;
