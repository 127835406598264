import { Route } from 'react-router-dom';
import { ComponentType, ReactNode } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import storageService from 'services/storage-service';

type WrapperProps = {
  children: ReactNode;
};

const Wrapper = ({ children }: WrapperProps) => {
  const { isAuthenticated, loginWithRedirect } = useAuth0();
  if (isAuthenticated) {
    return <>{children}</>;
  }
  const href = window.location.href.toLowerCase();
  if (
    href.indexOf('silent') === -1 &&
    href.indexOf('access_token=') === -1 &&
    href.indexOf('error=') === -1 &&
    href.indexOf('code=') === -1
  ) {
    storageService.set('ls.redirectPostLoginUrl', href); // save the last page
  }
  loginWithRedirect();
  return <></>;
};

type PrivateRouteProps = {
  component: ComponentType<any>;
  path?: string;
};

const PrivateRoute = ({ component: Component, ...rest }: PrivateRouteProps) => (
  <Route {...rest}>
    <Wrapper>
      <Component />
    </Wrapper>
  </Route>
);

export default PrivateRoute;
