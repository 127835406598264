type Texts = {
  [key: string]: { title: string; link: string };
};

export const pageSettings = {
  orderitem: { title: 'Item', link: '/orderitem', itemPage: true },
  shipinitem: { title: 'ShipIn', link: '/shipinitem', itemPage: true },
  inventoryitem: { title: 'Inventory', link: '/inventoryitem', itemPage: true },
  shipoutitem: { title: 'ShipOut', link: '/shipoutitem', itemPage: true },
  qcitem: { title: 'Quality Control', link: '/qcitem', itemPage: true },
  poitem: { title: 'Purchase Order', link: '/poitem', itemPage: true },
  invoiceitem: { title: 'Invoice', link: '/invoiceitem', itemPage: true },
  shipin: { title: 'ShipIn', link: '/shipin', itemPage: true },
  inventory: { title: 'Inventory', link: '/inventory', itemPage: false },
  shipout: { title: 'ShipOut', link: '/shipout', itemPage: false },
  qc: { title: 'Quality Control', link: '/qc', itemPage: false },
  po: { title: 'Purchase Order', link: '/po', itemPage: false },
  invoice: { title: 'Invoice', link: '/invoice', itemPage: false },
  dbupdates: { title: 'DB Updates', link: '/dbupdates', itemPage: false },
  orders: { title: 'Orders', link: '/orders', itemPage: false },
  orderitems: { title: 'Items', link: '/orderitems', itemPage: false }
};

const localPageSettigs: Texts = pageSettings;

export const getTitle = (pathname: string) => {
  const realPathName = pathname.split('/')?.[1];

  if (realPathName && localPageSettigs[realPathName]?.title) {
    return localPageSettigs[realPathName]?.title;
  }
  return '';
};

export const getLink = (pathname: string, orderItemId: string | number) => {
  if (pathname && `${localPageSettigs[pathname]?.link}/${orderItemId}`) {
    return `${localPageSettigs[pathname]?.link}/${orderItemId}`;
  }
  return '';
};

type MenuItem = {
  text: string;
  link: string;
};

export const getItemPageMenuItems = (pathname: string, orderItemId: string | number) => {
  const realPathName = pathname.split('/')?.[1] || '';

  if (realPathName?.toLowerCase() === 'orders') {
    return null;
  }

  const itemPages = Object.values(pageSettings).reduce<Array<MenuItem>>((acc, item) => {
    if (item.itemPage && !acc.find(i => i.text === item.title)) {
      return [
        ...acc,
        {
          text: item.title,
          link: `${item.link}/${orderItemId}`
        }
      ];
    }
    return [...acc];
  }, []);

  return { displayText: getTitle(pathname), pages: itemPages };
};
