import ReactDOM from 'react-dom';
import { ThemeProvider } from 'styled-components';
import { HashRouter } from 'react-router-dom';
import AuthProvider from './auth-provider';
import 'normalize.css';
import 'assets/styles.css';
import { theme } from './theme';
import { App } from './app';

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <HashRouter>
      <AuthProvider>
        <App />
      </AuthProvider>
    </HashRouter>
  </ThemeProvider>,
  document.getElementById('root')
);
