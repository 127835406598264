import styled from 'styled-components';

type Props = {
  name: string;
};

export const Icon = styled.span<Props>`
  /* stylelint-disable font-family-no-missing-generic-family-keyword*/
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'styled-react-icons' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  &:before {
    content: ${props => props.theme.icons[props.name]};
  }
`;

export default Icon;
