import { Api } from 'types/api-types';

type ApisObject = {
  [key: string]: Api;
};

export const apis: ApisObject = {
  deletedDiamonds: {
    url: () => `DeletedDiamond/filter`,
    method: 'post'
  },
  restoreLots: {
    url: () => `DeletedDiamond/RestoreLots`,
    method: 'post'
  },
  activeUploads: {
    url: () => `Upload/Monitor/ActiveUploads`,
    method: 'get'
  },
  uploadsHistory: {
    url: () => `Upload/UploadsHistory`,
    method: 'post'
  },
  exportUploadToExcel: {
    url: ({ accountId, uploadId }) => `Upload/ExportToExcel/${accountId}/${uploadId}`,
    method: 'post'
  },
  clearCache: {
    url: ({ accountId }) => `Upload/AddInvalidAccount/${accountId}`,
    method: 'post'
  },
  retryUpload: {
    url: ({ uploadId }) => `Upload/RetryUpload/${uploadId}`,
    method: 'post'
  },
  redoServiceUpload: {
    url: ({ uploadId }) => `Upload/RedoUpload/${uploadId}`,
    method: 'post'
  },
  removeServiceUpload: {
    url: ({ uploadId }) => `Upload/Remove/${uploadId}`,
    method: 'post'
  },
  accountAutoComplete: {
    url: ({ onlyMembers, term }) => `Device/AccountAutoComplete/${onlyMembers}/${term}`,
    method: 'get'
  },
  getDevices: {
    url: () => `Device/GetDevices`,
    method: 'post'
  },
  deviceStatus: {
    url: () => `Device/DeviceStatus`,
    method: 'put'
  },
  deleteDevice: {
    url: () => `Device/Delete`,
    method: 'post'
  },
  exportDevicesToExcel: {
    url: () => `Device/GetDevices/ExportToExcel`,
    method: 'post'
  },
  getAccountInformation: {
    url: () => `Device/GetAccountInformation`,
    method: 'post'
  },
  getDefaultSettings: {
    url: ({ country, city, packageName, isPrimaryMember }) =>
      `Device/DefaultSettings?country=${country}&city=${city}&packageName=${packageName}&isPrimaryMember=${isPrimaryMember}`,
    method: 'get'
  },
  getAllDefaultSettings: {
    url: () => `Device/DefaultSettings/All`,
    method: 'get'
  },
  updateDefaultSettings: {
    url: () => `Device/DefaultSettings`,
    method: 'post'
  },
  deleteDefaultSetting: {
    url: ({ DefaultSettingId }) => `Device/DefaultSettings/${DefaultSettingId}`,
    method: 'delete'
  },
  deleteMobileDevices: {
    url: () => `Device/DeleteMobileDevices`,
    method: 'delete'
  },
  getUserSettings: {
    url: ({ UserId }) => `Device/UserSettings/${UserId}`,
    method: 'get'
  },
  updateUserSettings: {
    url: () => `Device/UserSettings`,
    method: 'put'
  },
  userAdditionalSettings: {
    url: () => `Device/UserAdditionalSettings`,
    method: 'post'
  },
  CertRefresh: {
    url: () => `CertRefresh/Certificate`,
    method: 'post'
  },
  companyLogo: {
    url: (accountId: string | number) => `BackOffice/Account/${accountId}/Logo`,
    method: 'get'
  },
  currentContactInfo: {
    url: () => `BackOffice/CurrentContactInfo`,
    method: 'get'
  },
  uploadColumnsGetDictionary: {
    url: () => `UploadColumns/Dictionary`,
    method: 'get',
    gateway: 'gemstones'
  },
  uploadColumnsGetList: {
    url: ({ pageNum, perPage, field }: { pageNum: number; perPage: number; field: string }) =>
      `UploadColumns?CurrentPage=${pageNum}&RecordsPerPage=${perPage}&field=${field}`,
    method: 'get',
    gateway: 'gemstones'
  },
  uploadColumnsAddColumn: {
    url: () => `UploadColumns`,
    method: 'post',
    gateway: 'gemstones'
  },
  uploadColumnsExport: {
    url: ({ pageNum, perPage, field }: { pageNum: number; perPage: number; field: string }) =>
      `UploadColumns/Export?CurrentPage=${pageNum}&RecordsPerPage=${perPage}&field=${field}`,
    method: 'get',
    gateway: 'gemstones'
  },
  uploadColumnsDeleteById: {
    url: (id: number) => `UploadColumns/${id}`,
    method: 'delete',
    gateway: 'gemstones'
  },
  uploadColumnsUpdateById: {
    url: (id: number) => `UploadColumns/${id}`,
    method: 'put',
    gateway: 'gemstones'
  },
  validValuesGetDictionary: {
    url: () => `ValidValues/Dictionary`,
    method: 'get',
    gateway: 'gemstones'
  },
  validValuesGetList: {
    url: ({
      pageNum,
      perPage,
      field,
      value
    }: {
      pageNum: number;
      perPage: number;
      field: string;
      value: string;
    }) =>
      `ValidValues?CurrentPage=${pageNum}&RecordsPerPage=${perPage}&field=${field}&value=${value}`,
    method: 'get',
    gateway: 'gemstones'
  },
  validValuesAddColumn: {
    url: () => `ValidValues`,
    method: 'post',
    gateway: 'gemstones'
  },
  validValuesExport: {
    url: ({ pageNum, perPage, field }: { pageNum: number; perPage: number; field: string }) =>
      `ValidValues/Export?CurrentPage=${pageNum}&RecordsPerPage=${perPage}&field=${field}`,
    method: 'get',
    gateway: 'gemstones'
  },
  validValuesDeleteById: {
    url: (id: number) => `ValidValues/${id}`,
    method: 'delete',
    gateway: 'gemstones'
  },
  validValuesUpdateById: {
    url: (id: number) => `ValidValues/${id}`,
    method: 'put',
    gateway: 'gemstones'
  },
  valuesManagmentGetList: {
    url: ({ pageNum, perPage, field }: { pageNum: number; perPage: number; field: string }) =>
      `ValuesManagement?CurrentPage=${pageNum}&RecordsPerPage=${perPage}&field=${field}`,
    method: 'get',
    gateway: 'gemstones'
  },
  valuesManagmentExport: {
    url: ({ pageNum, perPage, field }: { pageNum: number; perPage: number; field: string }) =>
      `ValuesManagement/Export?CurrentPage=${pageNum}&RecordsPerPage=${perPage}&field=${field}`,
    method: 'get',
    gateway: 'gemstones'
  },
  valuesManagmentAddValue: {
    url: () => `ValuesManagement`,
    method: 'post',
    gateway: 'gemstones'
  },
  valuesManagmentUpdateVal: {
    url: () => `ValuesManagement`,
    method: 'put',
    gateway: 'gemstones'
  },
  valuesManagmentDeleteById: {
    url: (id: number) => `ValuesManagement/${id}`,
    method: 'delete',
    gateway: 'gemstones'
  }

  /*  lists: {
    url: `MyLists`,
    urlParams: {
      currentPage: 1,
      itemsPerPage: 25,
      sortBy: 'DateUpdated',
      sortOrder: 'descending',
      roles: 'Owner'
    }
  } */
};
